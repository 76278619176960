.header {
  display: flex;
  justify-content: space-between;
}
.right-header {
  margin-right: 16px;
  display: flex;
  align-items: center;
}
.ant-dropdown-link {
  cursor: pointer;
}
.site-layout-background .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

.site-layout-background .trigger:hover {
  color: @primary-color;
}

.site-layout-background .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}

.site-layout .site-layout-background {
  background: #fff;
  border-radius: 6px;
  padding: 16px;
}
@primary-color: #10AC84;@processing-color: #3F9BE0;@success-color: #1DD1A1;@error-color: #EB3F2B;@warning-color: #F1AD49;@border-radius-base: 6px;@card-padding-base: 16px;