.ant-carousel .slick-slide {
  text-align: center;
  overflow: hidden;
}
.ant-carousel .slick-slide h3 {
  color: #fff;
}
.ant-carousel {
  margin-bottom: 16px;
}
.ant-carousel .slick-slide .image {
  width: 100%;
}
.ant-carousel .slick-slide .image-wrapper {
  width: 100%;
  padding-top: 50%;
  background-size: cover!important;
  background-position: center center!important;
}
@primary-color: #10AC84;@processing-color: #3F9BE0;@success-color: #1DD1A1;@error-color: #EB3F2B;@warning-color: #F1AD49;@border-radius-base: 6px;@card-padding-base: 16px;