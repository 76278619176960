.amount-container {
  display: flex;
  height: 150.6px;
  box-sizing: border-box;
  box-shadow: 0px 7px 20px rgba(0, 0, 0, 0.07);
  border-radius: 12px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.credit-text {
  font-size: 17px;
  font-weight: 600;
  text-align: center;
}

.actual-text {
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  color: #a7acbd;
}

.amount-container.active {
  border: 2px solid #10ac84;
}

@primary-color: #10AC84;@processing-color: #3F9BE0;@success-color: #1DD1A1;@error-color: #EB3F2B;@warning-color: #F1AD49;@border-radius-base: 6px;@card-padding-base: 16px;