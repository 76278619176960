.facebook-btn, .google-btn {
  width: 300px;
  padding: 16px;
  border: none;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.07);
  border-radius: 6px;
  font-weight: bold;
  cursor: pointer;
}
.facebook-btn:focus, .google-btn:focus {
  outline: none;
}
.facebook-btn {
  background: #3071B3;
  color: #fff;
  margin-bottom: 16px;
}
.google-btn {
  color: #32325D;
  background: #fff;
}
@primary-color: #10AC84;@processing-color: #3F9BE0;@success-color: #1DD1A1;@error-color: #EB3F2B;@warning-color: #F1AD49;@border-radius-base: 6px;@card-padding-base: 16px;