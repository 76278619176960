.general-info {
  text-align: center;
}
.list-general-icon {
  margin-bottom: 6px;
  margin-top: 4px;
  width: 16px;
  height: 16px;
}
.category-in-item {
  position: absolute;
  top: 8px;
  left: 8px;
  z-index: 99;
}
.deal-tag {
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 99;
}
@primary-color: #10AC84;@processing-color: #3F9BE0;@success-color: #1DD1A1;@error-color: #EB3F2B;@warning-color: #F1AD49;@border-radius-base: 6px;@card-padding-base: 16px;