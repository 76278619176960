@neutral-1-color: #373b50;
@neutral-2-color: #5e657b;
@neutral-3-color: #a7acbd;
@neutral-4-color: #c2c6d1;
@neutral-5-color: #f1f2f3;
@neutral-6-color: #f8f8f8;

.body {
  color: @neutral-1-color;
  background: @neutral-5-color;
}
.no-p-img {
  width: 100%;
  padding-top: 50%;
  background-color: @neutral-5-color;
  background-image: url("/img/p-no-image.svg");
  background-repeat: no-repeat;
  background-size: 68px 68px;
  background-position: center;
}
// .my-16 {
//   margin-top: 16px;
//   margin-bottom: 16px;
// }
// .mt-16 {
//   margin-top: 16px;
// }
// .mb-16 {
//   margin-bottom: 16px;
// }
// .mt-8 {
//   margin-top: 8px;
// }
// .mb-8 {
//   margin-bottom: 8px;
// }
// .mr-8 {
//   margin-right: 8px;
// }
// .ml-8 {
//   margin-left: 8px;
// }
@sizes: 0, 2px, 4px, 8px, 12px, 16px, 24px, 32px;
@length: length(@sizes);
.loop-sizes(@sizes; @index) when (@index > 0) {
  .loop-sizes(@sizes; (@index - 1));
  @size: extract(@sizes, @index);
  .my-@{size} {
    margin-top: @size!important;
    margin-bottom: @size!important;
  }
  .mx-@{size} {
    margin-left: @size!important;
    margin-right: @size!important;
  }
  .mt-@{size} {
    margin-top: @size!important;
  }
  .mb-@{size} {
    margin-bottom: @size!important;
  }
  .ml-@{size} {
    margin-left: @size!important;
  }
  .mr-@{size} {
    margin-right: @size!important;
  }

  .p-@{size} {
    padding: @size!important;
  }
  .py-@{size} {
    padding-top: @size!important;
    padding-bottom: @size!important;
  }
  .px-@{size} {
    padding-right: @size!important;
    padding-left: @size!important;
  }
  .pt-@{size} {
    padding-top: @size!important;
  }
  .pb-@{size} {
    padding-bottom: @size!important;
  }
  .pl-@{size} {
    padding-left: @size!important;
  }
  .pr-@{size} {
    padding-right: @size!important;
  }
}
.loop-sizes(@sizes; @length);

.ant-typography-danger {
  color: #ff4d4f;
}
.text-primary {
  color: @primary-color;
}
.text-danger {
  color: @error-color!important;
}

.text-dark {
  color: @neutral-1-color;
}

.text-dark-grey {
  color: @neutral-2-color;
}

.text-grey {
  color: @neutral-3-color;
}

.text-light-grey {
  color: @neutral-4-color;
}

.text-light {
  color: @neutral-5-color;
}

.bg-primary {
  background-color: @primary-color;
}

.bg-dark {
  background-color: @neutral-1-color;
}

.bg-dark-grey {
  background-color: @neutral-2-color;
}

.address-text {
  font-size: 11px;
}

.bg-grey {
  background-color: @neutral-3-color;
}

.bg-light-grey {
  background-color: @neutral-4-color;
}

.bg-light {
  background-color: @neutral-5-color;
}

hr {
  line-height: 1px;
  border: none;
  border-bottom: 1px @neutral-5-color solid;
  margin: 0;
}

.fwb {
  font-weight: bold;
}
.fwn {
  font-weight: normal;
}
.mt-0 {
  margin-top: 0;
}
.mb-0 {
  margin-bottom: 0;
}

.section-title {
  margin-bottom: 24px;
}

.icon-24 {
  width: 24px;
  height: 24px;
}

.features {
  .ant-tag {
    transition: none;
    cursor: pointer;
    margin-bottom: 8px;
    padding: 8px;
  }
  .ant-tag-checkable:not(.ant-tag-checkable-checked):hover {
    color: #373b50;
  }
  .ant-tag-checkable {
    border: 2px solid #f1f2f3;
  }
  .ant-tag-checkable:active,
  .ant-tag-checkable-checked {
    color: #373b50;
    background: #fff;
    border: 2px solid @primary-color;
  }
}
.general-icon {
  margin-right: 8px;
}
.feature-item {
  display: flex;
  align-items: center;
  height: 100%;
}
.feature-item span {
  line-height: 1.3;
  margin-left: 8px;
}
.w-100 {
  width: 100%;
}
.ant-carousel {
  margin-bottom: 0;
}
.d-flex {
  display: flex;
}
.aic {
  align-items: center;
}
.jcc {
  justify-content: center;
}
.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.flex-row {
  flex-direction: row;
}
.jcb {
  justify-content: space-between;
}
.alc {
  align-items: center;
}
.lh-1 {
  line-height: 1;
}
.vam {
  vertical-align: middle;
}
.status,
.status.with-bg .status-text {
  display: flex;
  align-items: center;
}
.status span {
  font-weight: normal;
  vertical-align: middle;
}
.status .badge {
  width: 8px;
  height: 8px;
  border-radius: 8px;
  display: inline-block;
  margin-right: 8px;
}

.status.with-bg {
  display: block;
  padding: 8px;
  border-radius: 6px;
}

.status.status-active.with-bg {
  background: fade(@success-color, 10%);
}
.status.status-draft.with-bg {
  background: fade(@processing-color, 10%);
}
.status.status-refused.with-bg,
.status.status-prevented.with-bg {
  background: fade(@error-color, 10%);
}
.status.status-reviewing.with-bg {
  background: fade(@warning-color, 10%);
}
.status.status-inactive.with-bg {
  background: fade(@neutral-3-color, 10%);
}

.status.status-active .badge {
  background: @success-color;
}
.status.status-draft .badge {
  background: @processing-color;
}
.status.status-refused .badge {
  background: @error-color;
}
.status.status-prevented .badge {
  background: @error-color;
}
.status.status-reviewing .badge {
  background: @warning-color;
}
.status.status-inactive .badge {
  background: @neutral-3-color;
}
.relative {
  position: relative;
}
.side-section {
  position: fixed !important;
  padding-left: 16px;
  top: 80px;
  right: 32px;
  width: 259px;
}
.main-section {
  width: calc(100% - 275px);
}
@media (max-width: 1199px) {
  .side-section {
    width: 184px;
  }
  .main-section {
    width: calc(100% - 200px);
    .ant-statistic-title {
      font-size: 12px !important;
    }
    .ant-statistic-content {
      font-size: 14px !important;
    }
  }
}
.clearfix::after {
  display: block;
  content: "";
  clear: both;
}
.float-left {
  float: left;
}
.float-right {
  float: right;
}
.text-default,
.text-default:hover {
  color: inherit;
}

.countdown-resend .ant-statistic {
  display: flex;
  justify-content: center;
  align-items: center;
}
.countdown-resend .ant-statistic-content {
  font-size: 14px;
  margin-left: 4px;
  line-height: 1.5715;
  margin-bottom: 4px;
  color: rgba(0, 0, 0, 0.45);
}

.border-right {
  border-right: 1px solid @neutral-5-color;
}
.marker {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 26px;
  height: 32px;
  user-select: none;
  transform: translate(-50%, -75%);
}
.notification-item {
  padding: 16px;
  background: #fff;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.05);
}
.notification-content {
  display: block;
  white-space: break-spaces;
}
.ant-layout-header.site-layout-background.header {
  border-bottom: 1px solid @neutral-5-color;
}
.menu-notification {
  width: 300px;
  max-height: 500px;
  overflow: auto;
  -webkit-box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05) !important;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05) !important;
  .ant-menu-item {
    line-height: 1.5 !important;
    height: auto !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .ant-menu-item-selected {
    background-color: inherit !important;
    color: inherit !important;
  }
}
.notification-icon {
  width: 40px;
  padding-top: 6px;
}
.notification-time {
  font-size: 0.85em;
  color: @neutral-3-color;
}
.unread-bg {
  background: #f2f4f8;
  .notification-content {
    font-weight: bold;
  }
}
.mark-all-read {
  padding: 8px 16px !important;
  text-align: right;
}
h3.required::before,
.required .ant-form-item-label > label::before {
  display: inline-block;
  margin-right: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: "*";
}
.error-image {
  margin-top: -8px;
  color: #ff4d4f;
  margin-bottom: 16px;
}
.price-desc {
  width: calc(50% - 6px);
  div {
    display: flex;
    align-items: center;
    height: 30px;
    margin-top: 32px;
  }
}
.site-checkbox-all-wrapper {
  padding-bottom: 8px;
  border-bottom: 1px solid #e9e9e9;
  margin-bottom: 12px;
}
.display-none {
  display: none;
}
.detail-sidebar {
  padding-left: 16px;
}
@media (max-width: 1199px) {
  .detail-sidebar {
    padding-top: 16px;
    padding-left: 0;
  }
}
@media (min-width: 1200px) {
  .sticky-sidebar-lg {
    position: sticky !important;
    top: 0;
  }
}
.offer-text {
  color: #eb782b !important;
}
.loading-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.7;
  display: flex;
  z-index: 9999;
}

.loading-component {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #10ac84;
}

.content-text {
  margin-top: 24px;
  size: 17px;
  color: grey;
}
.deal-message {
  color: #4c65ba;
  background: #eef0f9;
  padding: 8px 12px;
  border-radius: 6px;
}
.deal-message.error {
  color: #eb3f2b;
  background: #fce2df;
}
.bg-offer {
  background: linear-gradient(269.99deg, #f1ad49 0%, #eb3f2b 100%) !important;
  border: none !important;
}
.text-offer {
  color: #f06f60 !important;
}
.border-left {
  border-left: 1px solid @neutral-5-color;
}
.text-line-through {
  text-decoration: line-through;
}
.gradient-box {
  position: relative;
  padding: 2px;
  box-sizing: border-box;
  background-clip: padding-box;
  background: transparent;
  border-radius: 12px;
  .content {
    background: white;
    position: relative;
    border-radius: 10px;
  }
  &:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: inherit;
    background: linear-gradient(269.99deg, #f1ad49 0%, #eb3f2b 100%);
  }
}
.messsage-frame {
  background: #eef0f9;
  color: #4c65ba;
  padding: 8px 12px;
}
.text-white {
  color: white !important;
}
.demand-item {
  height: 284px;
  cursor: pointer;
  border: 2px solid #fff;
  .address {
    background: @neutral-5-color;
    padding: 8px;
    border-radius: 6px;
    height: 60px;
    .text {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      line-height: 1.3;
    }
  }
  &.active {
    border-color: @primary-color;
  }
}
.general-title {
  width: 110px;
  display: inline-block;
}
.simple-feature {
  background: @neutral-5-color;
  border-radius: 4px;
  padding: 4px 8px;
  margin-bottom: 8px;
  margin-right: 8px;
  display: inline-block;
  font-size: 12px;
}

@primary-color: #10AC84;@processing-color: #3F9BE0;@success-color: #1DD1A1;@error-color: #EB3F2B;@warning-color: #F1AD49;@border-radius-base: 6px;@card-padding-base: 16px;