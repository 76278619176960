.deposit-btn {
  width: 150px;
  height: 36px;
}

.nganluong-deposit-btn {
  border: none;
  width: 100%;
  height: 36px;
  margin-top: 8px;
  margin-bottom: 8px;
  font-size: 12px;
  font-weight: 600;
}

.nganluong-deposit-btn.inactive {
  background-color: #c2c6d1;
}

.nganluong-deposit-btn.active {
  background-color: #10AC84;
}
@primary-color: #10AC84;@processing-color: #3F9BE0;@success-color: #1DD1A1;@error-color: #EB3F2B;@warning-color: #F1AD49;@border-radius-base: 6px;@card-padding-base: 16px;